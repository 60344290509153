import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CDProcessingStyles } from './CDProcessingStyles';
import ThankyouTickGreen from '../../../images/Vector.png';
import ThankyouTick from '../../../images/group_769.png';
import SupportIcon from '../../../images/groupInfo.svg';
import EliteAccImg from '../../../images/EliteAccImg2.png';
import { clearContext } from '../../clearContext';
import { clearDoChecklistContext } from '../../CommercialDeposit/DragDropFiles/DocLabelTypes';
import { getAccessToken } from '../../../services/tokenService';
import LeadGenerationContext from '../../../context/LeadGenerationContext';

const ThankyouReviewCD = (props) => {
  const { thankyouChk, history } = props;
  // console.log('thankyouChk', thankyouChk);
  const [flag] = useState(thankyouChk);
  const { t } = useTranslation();
  const classes = CDProcessingStyles();
  const leadGenerationSubmission = useContext(LeadGenerationContext);
  const { flowTypeVerifyScreen } = useContext(LeadGenerationContext);
  const theme = useTheme();

  const isMobile600 = useMediaQuery(theme?.breakpoints?.down('600'), {
    defaultMatches: true,
  });

  const thankYouClass = flag ? classes.agreeAndSubmitContainer : '';
  useEffect(() => {
    leadGenerationSubmission.pauseTimerAndClearInterval();
    leadGenerationSubmission.clearPingTimer();
    // leadGenerationSubmission.setAccessToken('')
  }, []);
  const POP_ACTION = 'POP';
  const DEV = 'development';

  useEffect(() => {
    let unListenSubmission = null;
    unListenSubmission = history.listen(() => {
      if (history.action === POP_ACTION) {
        if (process.env.REACT_APP_ENVIRONMENT === DEV) {
          clearContext(leadGenerationSubmission);
          clearDoChecklistContext(leadGenerationSubmission);

          history.push('/');
        } else {
          let url = '';
          if (leadGenerationSubmission.location === 'PR') {
            url = `https://orientalbank.com/${leadGenerationSubmission.language?.toLowerCase()}`;
          } else if (leadGenerationSubmission.location === 'VI') {
            url = `https://orientalbank.com/${leadGenerationSubmission.language?.toLowerCase()}/${leadGenerationSubmission.location?.toLowerCase()}`;
          } else {
            url = `https://orientalbank.com/`;
          }
          window.location.href = url;
        }
      }
    });
    return unListenSubmission;
  }, []);

  const handleRoute = async () => {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      clearContext(leadGenerationSubmission);
      clearDoChecklistContext(leadGenerationSubmission);

      const token = await getAccessToken();
      if (token) {
        leadGenerationSubmission.setAccessToken(token);
        leadGenerationSubmission.pingTimer(token);
        leadGenerationSubmission.startTimer();
        history.push('/');
      }
    } else {
      let url = '';
      if (leadGenerationSubmission.isQR) {
        url = `https://orientalbank2016.sharepoint.com/sites/OrientalIntranet/SitePages/External-web-links.aspx`;
      } else if (leadGenerationSubmission.location === 'PR') {
        url = `https://orientalbank.com/${leadGenerationSubmission.language?.toLowerCase()}`;
      } else if (leadGenerationSubmission.location === 'VI') {
        url = `https://orientalbank.com/${leadGenerationSubmission.language?.toLowerCase()}/${leadGenerationSubmission.location?.toLowerCase()}`;
      } else {
        url = `https://orientalbank.com/`;
      }
      window.location.href = url;
    }
  };

  return (
    <div className={classes.mainContainer}>
      <Grid container className={classes.thankyouGrid}>
        <Grid item xs={12} sm={6} md={8}>
          <div className={`${classes.iconGrid} ${thankYouClass}`}>
            <img
              src={flag ? ThankyouTick : ThankyouTickGreen}
              width="72px"
              height="72px"
              alt={t('Profile picture')}
            />
          </div>

          {flag ? (
            <Typography
              className={`${classes.gracias} ${classes.centerText}`}
              style={{ marginBottom: isMobile600 ? '40px' : '' }}
            >
              {t('Your new CD is almost completed!', {
                defaultValue: 'Your new CD is almost completed!',
              })}
            </Typography>
          ) : (
            <Typography className={classes.gracias}>
              {t('Thank you!', {
                defaultValue: 'Thank you!',
              })}
            </Typography>
          )}

          {flag ? (
            <Typography className={classes.text1} style={{ display: isMobile600 ? 'none' : '' }}>
              {t(
                'Your application has been reviewed successfully and there are a few steps left to open your Certificate of Deposit',
                {
                  defaultValue:
                    'Your application has been reviewed successfully and there are a few steps left to open your Certificate of Deposit',
                }
              )}
            </Typography>
          ) : isMobile600 ? (
            <Typography className={classes.text1} style={{ fontWeight: 700 }}>
              {t('You have successfully submitted your application', {
                defaultValue: 'You have successfully submitted your application',
              })}
            </Typography>
          ) : (
            <Typography className={classes.text1}>
              {t(
                'We have received all your information, and an officer will be reviewing your application.',
                {
                  defaultValue:
                    'We have received all your information, and an officer will be reviewing your application.',
                }
              )}
            </Typography>
          )}

          <Typography className={classes.text3}>
            {t('What comes next?', {
              defaultValue: 'What comes next?',
            })}
          </Typography>

          {!flag && (
            <div className={classes.iconGrid}>
              <img src={SupportIcon} width="36px" height="36px" alt="Submission Success" />
              <Typography className={classes.text4}>
                <span style={{ fontWeight: 700, marginRight: '5px' }}>
                  {t('REVIEW', {
                    defaultValue: 'REVIEW',
                  })}
                  :
                </span>
                {t('We will review your application details in order to open your account.', {
                  defaultValue:
                    'We will review your application details in order to open your account.',
                })}
              </Typography>
            </div>
          )}

          <div className={classes.iconGrid}>
            <img src={SupportIcon} width="36px" height="36px" alt="Submission Success" />
            <Typography className={classes.text4}>
              <span style={{ fontWeight: 700, marginRight: '5px' }}>
                {t('FUNDING', {
                  defaultValue: 'FUNDING',
                })}
                :
              </span>
              {t(
                'We need to receive your initial deposit; this can take from 3 to 5 business days.',
                {
                  defaultValue:
                    'We need to receive your initial deposit; this can take from 3 to 5 business days.',
                }
              )}
            </Typography>
          </div>
          {flowTypeVerifyScreen !== 'emailCDReview' && (
            <div className={classes.iconGrid} style={{ marginBottom: '50px' }}>
              <img src={SupportIcon} width="36px" height="36px" alt="Submission Success" />
              <Typography className={classes.text4}>
                <span style={{ fontWeight: 700, marginRight: '5px' }}>
                  {t('SIGN DOCUMENTS', {
                    defaultValue: 'SIGN DOCUMENTS',
                  })}
                  :
                </span>
                {t(
                  'Once funding is completed, you will be receiving an email from us to sign your final documents.',
                  {
                    defaultValue:
                      'Once funding is completed, you will be receiving an email from us to sign your final documents.',
                  }
                )}
              </Typography>
            </div>
          )}

          {!flag && (
            <div className={classes.agreeAndSubmitContainer}>
              <Button
                autoFocus
                disableFocusRipple
                className={classes.thankyouButton}
                data-testid="ReturnToOrientalButtonTitle"
                onClick={handleRoute}
              >
                {t('Back to Home page', { defaultValue: 'Back to Home page' })}
              </Button>
            </div>
          )}
        </Grid>
        {!isMobile600 && (
          <Grid item xs={12} sm={6} md={4} className={classes.cardGrid}>
            <div>
              <img src={EliteAccImg} alt="layout" style={{ maxWidth: '100%', height: 'auto' }} />
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default withRouter(ThankyouReviewCD);
ThankyouReviewCD.propTypes = {
  thankyouChk: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
    listen: PropTypes.func,
    action: PropTypes.string,
  }),
};
