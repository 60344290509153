import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MANDATORY_FIELD_ERR } from '../../../constants';
import LeadGenerationContext from '../../../context/LeadGenerationContext';
import { CaptureLoanDetailsStyles } from '../../Consumer/CaptureLoanDetailsStyles';
import { commonUtility } from '../UtilityFunctions';
import { CDProcessingStyles } from './CDProcessingStyles';

export const FundingScreenCards = ({ accountsResponse, setIsSubmitClicked, isSubmitClicked }) => {
  const {
    setOrientalAccountCardValue,
    orientalAccountCardValue,
    setFundData,
    setFundDataErrors,
    fundData,
    fundDataErrors,
    customerNumber,
    setIsFundingScreenDirty,
    setIsScreenOneDirty,
    setIsScreenTwoDirty,
    setIsScreenThreeDirty,
    setIsScreenFourDirty,
    setIsScreenFiveDirty,
    setIsOffersScreenDirty,
    dormantAcntNo,
  } = useContext(LeadGenerationContext);
  const classes = CDProcessingStyles();
  const radioClass = CaptureLoanDetailsStyles();
  const { t } = useTranslation();

  const [radioValue, setRadioValue] = useState('');

  useEffect(() => {
    setRadioValue(orientalAccountCardValue?.Id);
  }, []);

  const handleRadioChange = (e) => {
    if (e.target.value) {
      setIsOffersScreenDirty(true);
      setIsFundingScreenDirty(true);
      setRadioValue(e.target.value);
      setIsScreenOneDirty(true);
      setIsScreenTwoDirty(true);
      setIsScreenThreeDirty(true);
      setIsScreenFourDirty(true);
      setIsScreenFiveDirty(true);
      const accountVal = accountsResponse.find((x) => x.Id === e.target.value);
      // setIsFundingScreenDirty
      console.log({ accountVal });
      setFundData({ ...fundData, accountNumber: commonUtility(accountVal.AccountNumber) });
      setOrientalAccountCardValue(accountVal);
      setIsSubmitClicked(false);
      setFundDataErrors({ ...fundDataErrors, accountNumber: '', depositAmount: '' });
    } else if (e.target.value === '') {
      setFundData({ ...fundData, accountNumber: '' });
      setFundDataErrors({ ...fundDataErrors, accountNumber: MANDATORY_FIELD_ERR });
    }
  };
  console.log('radioValue.toString()', radioValue.toString());
  console.log('dormant acnt', dormantAcntNo);
  return (
    <div style={{ marginBottom: '10px', marginTop: '20px' }}>
      <div className={`${radioClass.radioContainer} ${classes.radioLabelContainer}`}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="accountNumber"
          id="accountNumber_id"
          value={radioValue.toString()}
          style={{ width: '100%', flexDirection: 'row' }}
          onChange={handleRadioChange}
        >
          <Grid container xs={12} spacing={4}>
            {accountsResponse?.map((accountData) => (
              <Grid item lg={4} md={6} xs={12} key={accountData.Id}>
                <div
                  style={{
                    padding: '10px 15px 5px 0',
                    borderRadius: '10px',
                    height: '100%',
                    width: '100%',
                    background:
                      accountData.isDepositeError || accountData.isValid ? `#E4E9F2` : 'inherit',
                    border:
                      accountData.isDepositeError || accountData.isValid
                        ? `#E4E9F2`
                        : '1px solid #E6E6E6',
                    pointerEvents:
                      accountData.isDepositeError || accountData.isValid ? 'none' : 'auto',
                    opacity: accountData.isDepositeError || accountData.isValid ? 0.6 : 1,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        color="default"
                        classes={{ root: `${radioClass.radioRoot} ${classes.radioMargin}` }}
                        size="large"
                        value={accountData.Id.toString()}
                        inputProps={{
                          'data-testid': 'debtConsolidation_testid',
                        }}
                        id={accountData.Id}
                        name={accountData.Id}
                        onClick={handleRadioChange}
                        disabled={
                          (isSubmitClicked &&
                            fundDataErrors?.accountNumber?.includes('available balance') &&
                            accountData.Id === orientalAccountCardValue.Id) ||
                          // accountData.Id.toString() === dormantAcntNo ||
                          accountData.isDepositeError ||
                          accountData.isValid
                        }
                      />
                    }
                    className={`${classes.radioCard} ${classes.cardStyle}`}
                    label={
                      <InputLabel htmlFor="test" className={radioClass.radioLabelStyle}>
                        <Typography className={classes.radioBtnLabel1}>
                          {t(accountData?.AccountType, {
                            defaultValue: accountData?.AccountType,
                            nsSeparator: '|',
                          })}
                        </Typography>
                        {console.log('accountData', accountData)}
                        {console.log('customerNumber', customerNumber)}
                        <Typography className={classes.radioBtnLabel2}>
                          XXXX-XXXX-{`${accountData?.AccountNumber?.slice(-4)}`}
                          {customerNumber !== '' && accountData?.Balance ? (
                            <span className={classes.radioBtnLabel3}>
                              {' '}
                              - ${accountData?.Balance}
                            </span>
                          ) : (
                            <span className={classes.radioBtnLabel3}> </span>
                          )}
                        </Typography>
                      </InputLabel>
                    }
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </div>
      <div>
        {' '}
        <span className={classes.error_message} role="alert" data-testid="offers-error">
          {t(fundDataErrors?.accountNumber, {
            defaultValue: fundDataErrors?.accountNumber,
          })}
        </span>
      </div>
    </div>
  );
};

FundingScreenCards.propTypes = {
  accountsResponse: PropTypes.object,
  setIsSubmitClicked: PropTypes.func,
  isSubmitClicked: PropTypes.bool,
};
