/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  TextField,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, InputAdornment, InputLabel, useMediaQuery, useTheme } from '@material-ui/core';
import Plant from '../../../images/Plant.png';
import Icon from '../../../images/Icon.png';
import { CustomInput } from '../CustomInput/CustomInput';
import { commonTernary, formatDepositAmount, isPlaidProduct } from '../../commonCode';

import {
  ADDITIONAL_HEAD,
  CONTACT_INFO,
  INVALID_FORMAT_ERR,
  MANDATORY_FIELD_ERR,
  MINIMUM_DEPOSIT,
  MIN_DEPOSIT_ERR,
  REQUIRED,
} from '../../../constants';
import { commonUtility } from '../UtilityFunctions';
import { FundingScreenStyles } from '../StepTwo/FundingScreenStyles';
import LeadGenerationContext from '../../../context/LeadGenerationContext';
import { CommonStyles } from '../CommonStyles';
import Layout from '../../Layout';
import { CDProcessingStyles } from './CDProcessingStyles';
import { productNameURLMapping } from '../../../services/productNameURLMapping';
import Loader from '../../Loader/Loader';
import { CDOffers, CDSaveOffers } from '../../../services/formService';

const CertificateOfDeposit = (props) => {
  const [offersResponse, setOffersResponse] = useState([]);
  const [radioValue, setRadioValue] = useState('');
  const [loader, setLoader] = useState(false);
  const cdStyles = CDProcessingStyles();
  const classes = FundingScreenStyles();
  const styledClasses = CommonStyles();
  const { t } = useTranslation();
  const { history } = props;
  const leadGenerationContext = useContext(LeadGenerationContext);
  const {
    productName,
    cdFlowOffers,
    setCdFlowOffers,
    cdFlowOffersErr,
    setCdFlowOffersErr,
    setFundData,
    fundData,
    accessToken,
    applicationId,
    applicantId,
    language,
    location,
    utmCampaign,
    utmMedium,
    utmContent,
    utmTerm,
    utmSource,
    branchCode,
    productType,
    isFundingScreenDirty,
    setApplicationId,
    setIsFundingScreenDirty,
    setCurrentPage,
    flowTypeETB,
    setIsScreenOneDirty,
    setIsScreenTwoDirty,
    setIsScreenThreeDirty,
    setIsScreenFourDirty,
    setIsScreenFiveDirty,
    isOffersScreenDirty,
    setIsOffersScreenDirty,
  } = leadGenerationContext;

  const theme = useTheme();
  const isMobile600 = useMediaQuery(theme?.breakpoints?.down('600'), {
    defaultMatches: true,
  });

  const isMobile900 = useMediaQuery(theme?.breakpoints.down('900'));
  console.log({ fundData });
  useEffect(async () => {
    let response = null;
    setLoader(true);
    if (accessToken) {
      response = await CDOffers(accessToken, location);
      console.log({ offers: response });
      if (response) {
        const sortedResp = response.data;

        sortedResp?.sort((a, b) => {
          if (parseInt(a.term, 10) < parseInt(b.term, 10)) {
            return -1;
          }
          if (parseInt(a.term, 10) > parseInt(b.term, 10)) {
            return 1;
          }
          return 0;
        });
        setOffersResponse(sortedResp);
        setLoader(false);
      }
      setCurrentPage('certificate-of-deposit');
      setRadioValue(cdFlowOffers?.offers?.term);

      console.log('leadGenerationContext.productName', leadGenerationContext.productName);
    }
  }, [accessToken]);

  const handleRadioChange = (e) => {
    if (e.target.value) {
      setCdFlowOffersErr({
        ...cdFlowOffersErr,
        offers: '',
      });
      setRadioValue(e.target.value);
      setIsOffersScreenDirty(true);
      setIsFundingScreenDirty(true);
      setIsScreenOneDirty(true);
      setIsScreenTwoDirty(true);
      setIsScreenThreeDirty(true);
      setIsScreenFourDirty(true);
      setIsScreenFiveDirty(true);
      const accountVal = offersResponse.find((x) => x.term === e.target.value);
      accountVal.apr = parseFloat(accountVal.apr).toFixed(2);

      setCdFlowOffers((prevState) => ({
        ...prevState,

        offers: accountVal,
      }));
    }
  };
  const fields = [];

  const formValidCheck = () => {
    let valid = true;
    if (!cdFlowOffers.depositAmount) {
      valid = false;
      fields.push('depositAmount');
      setCdFlowOffersErr((prevState) => ({
        ...prevState,

        depositAmount: MANDATORY_FIELD_ERR,
      }));
    }
    if (Object.entries(cdFlowOffers.offers).length === 0) {
      valid = false;
      fields.push('offers');
      setCdFlowOffersErr((prevState) => ({
        ...prevState,

        offers: MANDATORY_FIELD_ERR,
      }));
    }
    return valid;
  };
  const setFocus = (fieldss) => {
    for (const field of fieldss) {
      if (!cdFlowOffers[field]) {
        const element = document.getElementById(field);
        if (element) {
          element.focus();
          break;
        }
      }
    }
  };
  const handleRouting = (result) => {
    if (result.data && result.data.applicationId) {
      setApplicationId(result.data.applicationId);
      if (flowTypeETB === 'EtbFlow') {
        history.push({
          pathname: `/${location?.toLowerCase()}/${language?.toLowerCase()}/express/retail-form/${productNameURLMapping(
            productName
          )}/${'etb-login'}`,
        });
      } else {
        history.push({
          pathname: `/${location?.toLowerCase()}/${language?.toLowerCase()}/retail-form/${productNameURLMapping(
            productName
          )}/${CONTACT_INFO}`,
        });
      }
    }
  };
  const handleContinue = async () => {
    console.log(
      'leadGenerationContext.productName in handleCon',
      leadGenerationContext.productName
    );
    setLoader(true);

    const isFormVaild = formValidCheck();
    setFocus(fields);

    if (isFormVaild && !cdFlowOffersErr?.depositAmount && !cdFlowOffersErr?.offers) {
      const data = {};
      data.applicationId = leadGenerationContext.customerAppId
        ? leadGenerationContext.customerAppId
        : commonTernary(applicationId, applicationId, null);
      data.applicantId = applicantId;
      data.language = language;
      data.location = location;
      data.utmCampaign = utmCampaign;
      data.utmMedium = utmMedium;
      data.utmContent = utmContent;
      data.utmTerm = utmTerm;
      data.utmSource = utmSource;
      data.branchCode = branchCode;
      data.productType = productType;
      data.productName = productName;

      data.cdTerm = cdFlowOffers.offers.term;
      data.interestPlan = cdFlowOffers.offers.planId;
      data.apy = cdFlowOffers.offers.apy;
      data.apr = cdFlowOffers.offers.apr;
      data.flowTypeETB = flowTypeETB;
      console.log('cdFlowOffers', cdFlowOffers);
      data.depositAmount = formatDepositAmount(cdFlowOffers.depositAmount, leadGenerationContext);
      console.log('data.depositAmount', data.depositAmount); // "1000"

      setFundData({ ...fundData, depositAmount: cdFlowOffers.depositAmount });

      if (isOffersScreenDirty) {
        const resp = await CDSaveOffers(data, accessToken);
        if (resp.status === 201) {
          setIsOffersScreenDirty(false);
          setLoader(false);
          handleRouting(resp);
        }
      } else {
        setIsOffersScreenDirty(false);
        if (flowTypeETB === 'EtbFlow') {
          history.push({
            pathname: `/${location?.toLowerCase()}/${language?.toLowerCase()}/express/retail-form/${productNameURLMapping(
              productName
            )}/${'etb-login'}`,
          });
        } else {
          history.push({
            pathname: `/${location?.toLowerCase()}/${language?.toLowerCase()}/retail-form/${productNameURLMapping(
              productName
            )}/${CONTACT_INFO}`,
          });
        }
      }
    }
    setLoader(false);
  };

  const handleChange = (e) => {
    if (e.target.value?.match(/^\d*$/)) {
      setIsOffersScreenDirty(true);
      setIsFundingScreenDirty(true);
      setIsScreenOneDirty(true);
      setIsScreenTwoDirty(true);
      setIsScreenThreeDirty(true);
      setIsScreenFourDirty(true);
      setIsScreenFiveDirty(true);
      setCdFlowOffers({ ...cdFlowOffers, depositAmount: e.target.value });
      if (e.target.value.endsWith('.00')) {
        const valueWithoutDecimal = e.target.value.slice(0, -3);
        console.log('slice value', valueWithoutDecimal);
        setCdFlowOffers({ ...cdFlowOffers, depositAmount: valueWithoutDecimal });
      }
      if (e.target.value === 0) {
        setCdFlowOffersErr({
          ...cdFlowOffersErr,
          depositAmount: INVALID_FORMAT_ERR,
        });
        setCdFlowOffers({ ...cdFlowOffers, depositAmount: e.target.value });
      } else if (!e.target.value) {
        setCdFlowOffers({ ...cdFlowOffers, depositAmount: '' });
        setCdFlowOffersErr({
          depositAmount: MANDATORY_FIELD_ERR,
        });
      } else if (e.target.value > 50000) {
        setCdFlowOffersErr({
          ...cdFlowOffersErr,
          depositAmount: 'The maximum limit for initial deposit is $50,000.',
        });
      } else if (e.target.value < 1000) {
        setCdFlowOffersErr({
          ...cdFlowOffersErr,
          depositAmount: 'A minimum deposit of $1000 is required to open this account.',
        });
        setCdFlowOffers({ ...cdFlowOffers, depositAmount: e.target.value });
      } else {
        setCdFlowOffersErr({
          ...cdFlowOffersErr,
          depositAmount: '',
        });
      }
    } else {
      setCdFlowOffersErr({
        depositAmount: INVALID_FORMAT_ERR,
      });
    }
  };

  const handleBlur = (e) => {
    const currencyValue = e?.target?.value?.replace(/^0+/, '');
    const newCurrencyValue = currencyValue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (newCurrencyValue && !e?.target?.value?.includes('.')) {
      setCdFlowOffers((prevState) => ({
        ...prevState,
        [e?.target?.name]: `${newCurrencyValue}.00`,
      }));
    }
  };

  const handleFocus = (e) => {
    if (e.target.value.endsWith('.00')) {
      const valueWithoutDecimal = e.target.value.slice(0, -3);
      const currencyValue = valueWithoutDecimal.toString().replace(/[^\w\s]/gi, '');
      console.log('currencyValue', currencyValue);

      console.log('slice value', valueWithoutDecimal);
      setCdFlowOffers({ ...cdFlowOffers, depositAmount: currencyValue });
    }
    // setCdFlowOffers((prevState) => ({
    //   ...prevState,
    //   [e.target.name]: currencyValue,
    // }));
  };

  const listItems = [
    t('Competitive interest rates according to the amount and term', {
      defaultValue: 'Competitive interest rates according to the amount and term',
    }),
    t('Interest computed and created monthly', {
      defaultValue: 'Interest computed and created monthly',
    }),
    t('You decide how to receive your interest payments', {
      defaultValue: 'You decide how to receive your interest payments',
    }),
    t('Automatic renewal', {
      defaultValue: 'Automatic renewal',
    }),
  ];

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <Grid
        container
        spacing={2}
        sx={{
          width: '100%',
          padding: '0 50px',
          display: 'flex',
          // alignItems: 'center',
          justifyContent: 'center',
          // border: '2px solid green',
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={7}>
          <div className="deposite-left" style={{ paddingTop: '35px' }}>
            <div className="heading">
              <h1 className={cdStyles.CDText1}>
                {t('Certificate of Deposit', {
                  defaultValue: 'Certificate of Deposit',
                })}
              </h1>
              <p className={cdStyles.CDText2}>
                {' '}
                {t('Grow your money at your convenience.', {
                  defaultValue: 'Grow your money at your convenience.',
                })}
              </p>
            </div>

            {!isMobile900 && (
              <div>
                <div style={{ marginTop: '50px' }} className="lists">
                  <p>
                    {t(`Benefits`, {
                      defaultValue: 'Benefits:',
                    })}
                  </p>
                  <List className="list">
                    {listItems.map((item, index) => (
                      <ListItem key={item} style={{ padding: '4px 0px' }}>
                        <ListItemIcon>
                          <ArrowRightIcon />
                        </ListItemIcon>
                        <ListItemText primary={item} />
                      </ListItem>
                    ))}
                  </List>
                </div>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    width: '100%',
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Grid item xs={12} md={6}>
                    <Typography
                      align="center"
                      sx={{
                        lineHeight: 1.2,
                      }}
                    >
                      <div className="text-container">
                        <h1 style={{ textAlign: 'center' }}>
                          {t('Just few simple steps to complete the process', {
                            defaultValue: 'Just few simple steps to complete the process',
                          })}
                        </h1>
                      </div>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <img src={Plant} alt="plant" style={{ width: '150px', height: '357px' }} />
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <div className={cdStyles.depositeRight}>
            {isMobile600 ? (
              <h1
                style={{
                  fontFamily: 'TTNormsProBold',
                  marginTop: '0px',
                  fontWeight: 700,
                  fontSize: '22px',
                }}
              >
                {t('Choose the offer for you', {
                  defaultValue: 'Choose the offer for you',
                })}
              </h1>
            ) : (
              <h1 style={{ fontFamily: 'TTNormsProBold', marginTop: '0px', fontWeight: 700 }}>
                {t('Choose the offer for you', {
                  defaultValue: 'Choose the offer for you',
                })}
              </h1>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            >
              <Grid item xs={12} className={classes.input_grid_padding}>
                <CustomInput
                  dataTestId="depositAmount_id"
                  id="depositAmount"
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  required
                  inputLabelAriaLabel={`${t('Enter deposit amount', {
                    defaultValue: 'Enter deposit amount',
                  })} ${t(REQUIRED, { defaultValue: REQUIRED })}`}
                  autoFocus
                  handleChange={handleChange}
                  handleFocus={handleFocus}
                  handleBlur={handleBlur}
                  label={
                    <span className={`${cdStyles.labels}`}>
                      {t('Enter deposit amount', { defaultValue: 'Enter deposit amount' })}
                    </span>
                  }
                  inputProps={{
                    'data-testid': 'deposit-amount',
                    maxLength: 7,
                    inputMode: 'numeric',
                  }}
                  placeholder={t('Min. amount $1,000.00', {
                    defaultValue: 'Min. amount $1,000.00',
                  })}
                  fullWidth
                  error={commonUtility(cdFlowOffersErr?.depositAmount)}
                  value={commonUtility(cdFlowOffers?.depositAmount)}
                />
                <span
                  className={classes.error_message}
                  role="alert"
                  data-testid="deposit-amount-error"
                >
                  {cdFlowOffersErr?.depositAmount === MIN_DEPOSIT_ERR &&
                  cdFlowOffers?.minDepositAmount
                    ? `${t(cdFlowOffersErr?.depositAmount, {
                        defaultValue: cdFlowOffersErr?.depositAmount,
                      })}${cdFlowOffers?.minDepositAmount}`
                    : t(cdFlowOffersErr?.depositAmount, {
                        defaultValue: cdFlowOffersErr?.depositAmount,
                      })}
                </span>
              </Grid>
              <div>
                <Box sx={{ width: '100%' }}>
                  <InputLabel
                    htmlFor="Select your offer"
                    data-testid="offers-testid"
                    required
                    aria-label="Select your offer"
                    className={`${cdStyles.labels}`}
                  >
                    {t('Select your offer', {
                      defaultValue: 'Select your offer',
                    })}
                  </InputLabel>
                  <RadioGroup
                    value={radioValue}
                    onChange={handleRadioChange}
                    id="offers"
                    name="offers"
                  >
                    {offersResponse?.map((offer, index) => (
                      <List key={`index-${offer.term}`}>
                        <ListItem
                          sx={{
                            backgroundColor: '#ffffff',
                            borderRadius: '5px',
                            height: isMobile600 ? '65px' : '45px',
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Grid item xs={8} md={8} lg={8}>
                              <FormControlLabel
                                value={cdFlowOffers?.offers?.term || ''}
                                control={
                                  <Radio
                                    color="default"
                                    size="small"
                                    value={offer?.term}
                                    inputProps={{
                                      'data-testid': 'debtConsolidation_testid',
                                    }}
                                    id={offer?.term}
                                    name={offer?.term}
                                    onClick={handleRadioChange}
                                    sx={{
                                      width: '38px',
                                      height: '38px',
                                      '&.Mui-checked': {
                                        color: '#661C69 !important',
                                      },
                                      '&.MuiRadio-root': {
                                        color: 'rgb(0 0 0 / 60%)',
                                      },
                                      '& svg': {
                                        width: '20px',
                                        height: '20px',
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <Typography sx={{ fontSize: { xs: '13px', sm: '15px' } }}>
                                    <span style={{ color: '#444444' }}>
                                      {t('CD Term', {
                                        defaultValue: 'CD Term',
                                      })}
                                    </span>{' '}
                                    -{' '}
                                    <span style={{ fontWeight: 'bold' }}>
                                      {offer?.term}{' '}
                                      {offer?.term === '1'
                                        ? t('Month', {
                                            defaultValue: 'Month',
                                          })
                                        : t('Months', {
                                            defaultValue: 'Months',
                                          })}
                                    </span>
                                  </Typography>
                                }
                              />
                            </Grid>

                            <Grid item xs={4} md={4} lg={4} className={cdStyles.offersTermsLayout}>
                              <Typography sx={{ fontSize: { xs: '13px', sm: '15px' } }}>
                                <span style={{ color: '#444444' }}>
                                  {t('APY', {
                                    defaultValue: 'APY',
                                  })}
                                </span>{' '}
                                - <span style={{ fontWeight: 'bold' }}>{offer.apy}</span>
                              </Typography>
                            </Grid>
                          </Grid>
                        </ListItem>
                      </List>
                    ))}
                  </RadioGroup>
                  <span className={classes.error_message} role="alert" data-testid="offers-error">
                    {t(cdFlowOffersErr?.offers, {
                      defaultValue: cdFlowOffersErr?.offers,
                    })}
                  </span>
                </Box>
              </div>
              {isMobile600 && (
                <div>
                  <Grid item md={12} sm={12} xs={12} sx={{ margin: '40px 0 !important' }}>
                    <Button
                      disableFocusRipple
                      onClick={handleContinue}
                      className={`${styledClasses.continue_button} ${cdStyles.continue}`}
                    >
                      {t('Continue', {
                        defaultValue: 'Continue',
                      })}
                    </Button>
                  </Grid>
                </div>
              )}
            </div>
          </div>
        </Grid>
        {!isMobile600 && (
          <Grid item md={12} sm={12} xs={12} sx={{ margin: '40px 0 !important' }}>
            <Button
              disableFocusRipple
              onClick={handleContinue}
              className={`${styledClasses.continue_button} ${cdStyles.continue}`}
            >
              {t('Continue', {
                defaultValue: 'Continue',
              })}
            </Button>
          </Grid>
        )}
      </Grid>
      {loader && (
        <div className="loader-style">
          <Loader />
        </div>
      )}
    </>
  );
};

export default withRouter(CertificateOfDeposit);
CertificateOfDeposit.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};
