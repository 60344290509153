import { makeStyles } from '@material-ui/core';
import { COLOR2, COLOR4, RADIO_COLOR } from '../../../constants';

export const CDProcessingStyles = makeStyles(
  (theme) => ({
    heading: {
      fontSize: '48px !important',
      fontWeight: '700 !important',
      paddingBottom: '3%',
    },
    subText: {
      fontSize: '20px !important',
      fontWeight: '400 !important',
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column !important',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center ',
    },
    fundsHeading: {
      fontSize: '30px !important',
      fontWeight: '700 !important',
      color: '#019249',
    },
    fundsSideHeading: {
      fontSize: '20px !important',
      fontWeight: '700 !important',
    },
    fundsSubText: {
      fontSize: '16px !important',
      padding: '3% 0 !important',
    },
    contentWrapperRow: {
      display: 'flex',
      color: '#661C69 !important',
      textDecoration: 'none !important',
      ':&hover': {
        textDecoration: 'underline !important',
        textDecorationColor: '#661C69 !important',
      },
    },
    radioBtnLabel1: {
      fontSize: '16px',
      fontWeight: '400',
      color: '#F58220',
    },
    radioBtnLabel2: {
      fontSize: '20px !important',
      fontWeight: '400',
      color: '#888888',
    },
    radioBtnLabel3: {
      fontSize: '20px !important',
      fontWeight: '700',
      color: '#000000',
    },
    radioLabelContainer: {
      alignItems: 'center !important',
      justifyContent: 'center',
    },
    radioMargin: {
      marginRight: '15px',
      marginLeft: '15px',
    },
    personal_button_container: {
      [theme.breakpoints.down('600')]: {
        justifyContent: 'center',
      },
    },
    review_grid_margin: {
      border: '1px solid #D0D0D0',
      borderRadius: '6px',
      boxShadow: '0px 4px 4px rgb(0 0 0 / 21%)',
      background: '#FFFFFF',

      padding: '30px',
      // background: `${COLOR4} 0% 0% no-repeat padding-box`,
      // borderRadius: '6px',
      opacity: 1,
      [theme.breakpoints.down('600')]: {
        border: 'none',
        borderRadius: '0px',
        boxShadow: 'none',
        padding: '0px',
      },
    },
    review_content: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px',
    },
    reviewCardStyle: {
      display: 'flex',
      justifyContent: 'flex-end',

      [theme.breakpoints.down('600')]: {
        justifyContent: 'flex-start',
      },
    },
    contextBox: {
      marginLeft: '15px',
    },
    cardHeading: {
      fontSize: '14px',
      fontWeight: '400',
      color: '#444444',
    },
    cardValue: {
      fontSize: '16px',
      fontWeight: '700',
      color: '#000000',
    },
    radioCard: {
      // border: '1px solid #E6E6E6 ',
      // padding: '0 20px 15px',
      margin: '0 !important',
      width: '100%',
      height: '100%',
      // borderRadius: '10px',
    },
    mainContainer: {
      background: '#F8F8F8',
      padding: '20px',
    },
    thankyouGrid: {
      background: '#FFFFFF',
      padding: '60px 75px 130px',
      [theme.breakpoints.down('1201')]: {
        padding: '60px 60px 130px',
      },
      [theme.breakpoints.down('md')]: {
        padding: '30px 30px 60px',
      },
    },
    gracias: {
      font: 'normal 700 36px/35px Arial',
      color: '#000000',
      [theme.breakpoints.down('md')]: {
        font: 'normal 700 36px/35px Arial',
      },
    },
    iconGrid: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
    },
    text1: {
      font: 'normal 400 16px/20px Arial',
      padding: '20px 0px 40px',
    },
    text2: {
      font: 'normal 700 16px/18px Arial',
      marginBottom: '55px',
    },
    text3: {
      font: 'normal 700 20px/38px Arial',
      marginBottom: '12px',
      color: '#E37617',
    },
    text4: {
      font: 'normal 400 16px/18px Arial',
      color: '#000000',
      marginLeft: '18px',
    },
    thankyouButton: {
      background: `${RADIO_COLOR} 0% 0% no-repeat padding-box`,
      color: COLOR4,
      display: 'flex',
      justifyContent: 'center',
      letterSpacing: '0.3px',
      font: 'normal normal normal 16px/18px Arial',
      width: '250px',
      height: '45px',
      borderRadius: '25px',
      opacity: 1,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: RADIO_COLOR,
      },
      '&:focus': {
        outline: `2px solid ${RADIO_COLOR}`,
        outlineOffset: `2px`,
      },
      // [theme.breakpoints.down('650')]: {
      //   marginTop: '50px',
      //   marginBottom: '50px',
      // },
      [theme.breakpoints.down('320')]: {
        width: 'fit-content',
      },
    },
    agreeAndSubmitContainer: {
      [theme.breakpoints.down('600')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    agreeAndSubmitButton: {
      background: `${RADIO_COLOR} 0% 0% no-repeat padding-box`,
      color: COLOR4,
      display: 'flex',
      justifyContent: 'center',
      letterSpacing: '0.3px',
      font: 'normal normal normal 16px/18px Arial',
      width: '180px',
      height: '45px',
      borderRadius: '25px',
      opacity: 1,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: RADIO_COLOR,
      },
      '&:focus': {
        outline: `2px solid ${RADIO_COLOR}`,
        outlineOffset: `2px`,
      },
    },
    cardGrid: {
      paddingLeft: '20px',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    continue: {
      float: 'right',
    },
    labels: {
      color: '#000000',
      fontFamily: 'Arial',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '18px',
      marginTop: '20px',
    },
    loader: {
      position: 'fixed',
      left: '0px',
      top: '0px',
      width: '100%',
      height: '100%',
      zIndex: '9999',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 10%',
    },
    offersTermsLayout: {
      borderLeft: '1px solid #E6E6E6',
      padding: '8px 0px 8px 10px',
      [theme.breakpoints.down('600')]: {
        padding: '8px 0px 8px 40px',
      },
      [theme.breakpoints.down('402')]: {
        padding: '8px 0px 8px 20px',
      },
      [theme.breakpoints.down('380')]: {
        padding: '8px 0px 8px 5px',
      },
    },
    CDTermsSize: {
      [theme.breakpoints.up(900)]: {
        [theme.breakpoints.down(916)]: {
          fontSize: '15px',
        },
      },
    },
    checkbox_label_style: {
      marginLeft: '2px',
      textAlign: 'left',
      font: 'normal normal 400 16px/18px Arial',
      letterSpacing: '0.11px',
      color: '#222B45',
      opacity: 1,
    },

    checkbox_content_position: {
      display: 'block',
      position: 'relative',
      top: '-20px',
      left: '32px',
      width: 'fit-content',
      marginRight: '30px',
      [theme.breakpoints.down('600')]: {
        top: '-18px',
        left: '27px',
        marginBottom: '25px',
      },
    },
    disabled_button: {
      font: 'normal normal bold 16px / 18px Arial',
      color: '#D0D0D0',
      width: '180px',
      border: '#F3F3F3',
      height: '45px',
      background: '#F3F3F3',
      borderRadius: '25px',
      textTransform: 'none',
      letterSpacing: '0.3px',
    },
    centerText: {
      [theme.breakpoints.down('600')]: {
        textAlign: 'center',
      },
    },
    CDText1: {
      fontWeight: 700,
      fontSize: '54px',
      lineHeight: '68px',
      color: '#f58220',
      margin: '0px',
      whiteSpace: 'none',
      fontFamily: 'TTNormsProBold',
      [theme.breakpoints.down('600')]: {
        fontSize: '28px',
        lineHeight: '30px',
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
    CDText2: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '20px',
      marginTop: '10px',
      fontFamily: 'Arial',
      [theme.breakpoints.down('600')]: {
        fontSize: '16px',
        lineHeight: '30px',
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
    depositeRight: {
      borderRadius: '36px',
      backgroundColor: '#f0e8f0',
      padding: '32px 48px 32px 48px',
      [theme.breakpoints.down('600')]: {
        width: '88vw',
        marginLeft: '-40px',
        padding: '32px 15px 10px 15px',
        marginBottom: '50px',
      },
      [theme.breakpoints.between('900', '1024')]: {
        padding: '32px 20px',
      },
    },
    error_message: {
      font: 'normal normal normal 14px/16px Arial',
      letterSpacing: '0.1px',
      color: COLOR2,
      opacity: 1,
      position: 'relative',
      top: '3px',
      display: 'block',
    },
    link: {
      textDecoration: 'none !important',
      '&[disabled]': {
        color: 'rgba(0, 0, 0, 0.26) !important',
      },
    },
  }),
  { index: 1 }
);
